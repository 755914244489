import japaneseCurry from "../../img/recipes/2_JapaneseCurry.webp";
import japaneseCurryMobile from "../../img/recipes/2_JapaneseCurry_Square.webp";
import spagettiBolognese from "../../img/recipes/1_SpaghettiBolognese.webp";
import spagettiBologneseMobile from "../../img/recipes/1_SpaghettiBolognese_Square.webp";
import katsuSando from "../../img/recipes/3_CrispyNuggetKatsu.webp";
import katsuSandoMobile from "../../img/recipes/3_KatsuSando_Square.webp";
import ceasarSalad from "../../img/recipes/4_CesarSaladSpicyNuggets.webp";
import ceasarSaladMobile from "../../img/recipes/4_CeasarSalad_Square.webp";
import crispyStripNachos from "../../img/recipes/5_CrispyStripNachos.webp";
import crispyStripNachosMobile from "../../img/recipes/5_CrispyStripNachos_Square.webp";
import SpicyKoreanBowl from "../../img/recipes/6_SpicyKoreanBowl.webp";
import SpicyKoreanBowlMobile from "../../img/recipes/6_SpicyKoreanBowl_Square.webp";
import MapoTofu from "../../img/recipes/7_MapoTofu.webp";
import MapoTofuMobile from "../../img/recipes/7_MapoTofu_Square.webp";
import GingerTeriyakiBowl from "../../img/recipes/8_GingerTeriyakiBowl.webp";
import GingerTeriyakiBowlMobile from "../../img/recipes/8_GingerTeriyakiBowl_Square.webp";
import SoboroDonburi from "../../img/recipes/9_SoboroDonburi.webp";
import SoboroDonburiMobile from "../../img/recipes/9_SoboroDonburi_Square.webp";
import CrispyCutletRiceBurger from "../../img/recipes/10_CrispyCutletRiceBurger.webp";
import CrispyCutletRiceBurgerMobile from "../../img/recipes/10_CrispyCutletRiceBurger_Square.webp";



export const recipeCards = [
    /** 
    {
        id:0,
        title:"Spaghetti Bolognese",
        path:"plant-based-spaghetti-bolognese/",
        preparationTime:"20",
        typeOfMeal:"Meal",
        desktopImage:spagettiBolognese,
        mobileImage:spagettiBologneseMobile

    },
    {
        id:1,
        title:"Spicy Korean Rice Bowl",
        path:"plant-based-spicy-korean-rice-bowl/",
        preparationTime:"15",
        typeOfMeal:"Meal",
        desktopImage:SpicyKoreanBowl,
        mobileImage:SpicyKoreanBowlMobile
    },
    {
        id:2,
        title:"Mapo Tofu",
        path:"plant-based-mapo-tofu/",
        preparationTime:"10-15",
        typeOfMeal:"Meal",
        desktopImage:MapoTofu,
        mobileImage:MapoTofuMobile
    },
    {
        id:3,
        title:"Ginger Teriyaki Udon Bowl",
        path:"plant-based-ginger-teriyaki-udon-bowl/",
        preparationTime:"15",
        typeOfMeal:"Meal",
        desktopImage:GingerTeriyakiBowl,
        mobileImage:GingerTeriyakiBowlMobile
    },
    {
        id:4,
        title:"Soboro Donburi",
        path:"plant-based-soboro-donburi/",
        preparationTime:"10",
        typeOfMeal:"Meal",
        desktopImage:SoboroDonburi,
        mobileImage:SoboroDonburiMobile
    },
    */
    {
        id:5,
        title:"Popcorn Bites Japanese Curry",
        path:"plant-based-popcorn-bites-japanese-curry/",
        preparationTime:"30-35",
        typeOfMeal:"Meal",
        desktopImage:japaneseCurry,
        mobileImage:japaneseCurryMobile
    },
    {
        id:6,
        title:"Crispy Nugget Katsu Sando",
        path:"plant-based-crispy-nugget-katsu-sando/",
        preparationTime:"10",
        typeOfMeal:"Snack",
        desktopImage:katsuSando,
        mobileImage:katsuSandoMobile
    },
    {
        id:7,
        title:"Caesar Salad with Spicy Nuggets",
        path:"caesar-salad-with-plant-based-spicy-nuggets/",
        preparationTime:"20",
        typeOfMeal:"Meal",
        desktopImage:ceasarSalad,
        mobileImage:ceasarSaladMobile
    },
    {
        id:8,
        title:"Crispy Strips Nacho",
        path:"plant-based-crispy-strips-nachos/",
        preparationTime:"20",
        typeOfMeal:"Snack",
        desktopImage:crispyStripNachos,
        mobileImage:crispyStripNachosMobile
    }
    /**,
    {
        id:9,
        title:"Crispy Cutlets Rice Burger",
        path:"plant-based-crispy-cutlet-rice-burger/",
        preparationTime:"20-30",
        typeOfMeal:"Meal",
        desktopImage:CrispyCutletRiceBurger,
        mobileImage:CrispyCutletRiceBurgerMobile
    }
     */
];