import React from "react";
import Layout from "../components/Layout";
import SmallButton from "../components/Button/SmallButton";
import OrangeButton from "../components/Button/OrangeButton";
import { Carousel } from "react-bootstrap";
import { recipeDetailData as data } from "../data/recipeDetail";
import InfoPanels from "../components/recipes/infoPanels";
import "../scss/recipedetail.scss";
import { recipeCards } from "../components/Card/recipeCards";
import RecipesSlider from "../components/RecipesSlider";
import GreenButton from "../components/Button/GreenButton";
import { StaticImage } from "gatsby-plugin-image";

const RecipeDetail = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;

  return (
    <Layout page={`recipeDetail`} title={data[i].metaTitle} description={data[i].metaDescription} navCustomBg={true}>
      <div className="recipeDetail">
        <StaticImage className="leafGraphic" src="../img/leafWatermark2.webp" />
        <StaticImage className="leafGraphicLeft" src="../img/leafWatermark2.webp" />

        <div className="productDetailContent">
          <div className="productDetails">
            <div className="productDesc">
              <h1 dangerouslySetInnerHTML={{ __html: data[i].title }}></h1>
              <br />
              <div className="recipeDetailpara recipeBlack" dangerouslySetInnerHTML={{ __html: data[i].description }} />
              <InfoPanels classes="d-none d-lg-flex" type={data[i].type} cookTime={data[i].cookTime} servings={data[i].servings} />
            </div>

            <div className="productCarousel">
              <div>
                <Carousel className="productarouselWrapper" interval={null}>
                  <Carousel.Item className="recipeCarouselItem">
                    <img loading="lazy" src={data[i].recipeImage1} alt={data[i].recipeImage1Alt ?? "hero1"} className=" d-block pc-img fullCarousel" />
                  </Carousel.Item>
                  <Carousel.Item className="productCarouselItem">
                    <img loading="lazy" src={data[i].recipeImage2} alt={data[i].recipeImage2Alt ?? "hero2"} className=" d-block pc-img fullCarousel" />
                  </Carousel.Item>
                  <Carousel.Item className="productCarouselItem">
                    <img loading="lazy" src={data[i].recipeImage3} alt={data[i].recipeImage3Alt ?? "hero3"} className=" d-block pc-img fullCarousel" />
                  </Carousel.Item>
                </Carousel>
              </div>
              <InfoPanels classes="d-lg-none" type={data[i].type} cookTime={data[i].cookTime} servings={data[i].servings} />
            </div>
          </div>
          <div className="ingredientsDirections">
            <div className="ingredients">
              <div className="title">
                <h2>Ingredients</h2>
                <hr />
              </div>

              {data[i].ingredients.map((ingredientRow, i) => {
                if (ingredientRow.hasOwnProperty("sectionName")) {
                  let { sectionName } = ingredientRow;

                  return (
                    <h6 key={sectionName} className="sectionName">
                      {sectionName}
                    </h6>
                  );
                } else {
                  let { ingredient, preparation, quantity, unit } = ingredientRow;

                  return (
                    <div key={ingredient} className="ingredientRow">
                      <div className="ingredient">
                        <p className={`name ${i === 0 && "firstIngredient"}`}>
                          {ingredient}
                          {preparation !== "" ? ", " : " "}
                          {preparation !== "" && <small className="preparation">{preparation}</small>}
                        </p>
                      </div>

                      {quantity !== unit ? (
                        <p className="quantity">
                          {quantity} {unit}
                        </p>
                      ) : (
                        <p className="quantity">{quantity}</p>
                      )}
                    </div>
                  );
                }
              })}
            </div>

            <div className="directions">
              <div className="title">
                <h2>Directions</h2>
                <hr />
              </div>

              {data[i].procedures.map((procedure, i) => (
                <div className="directionRow" key={`step${i + 1}`}>
                  <p className="step">{i + 1}</p>
                  <div className="procedure" dangerouslySetInnerHTML={{ __html: procedure }}></div>
                </div>
              ))}
            </div>
          </div>
          <div className="madeWith">
            <div className="productDetailTitle">
              <h2>Made with:</h2>
              <span>{data[i].product.title}</span>
            </div>

            <img src={data[i].product.img} alt="product" />

            <div className="productDetailBtns">
              <GreenButton msg="view product" icon="forward" to={data[i].product.path} target="_blank" />

              <OrangeButton
                msg="buy now"
                color="orange"
                icon="cart"
                to="https://redmart.lazada.sg/first-pride-123975828/?spm=a2o42.pdp_revamp.0.0.41142181cPkVQw&m=redmart&type=brand"
                target="_blank"
                yellow="#fff"
              />
            </div>
          </div>

          <div className="relatedProd">
            <h2>You might also like...</h2>

            <RecipesSlider recipes={recipeCards} currentRecipe={data[i].id} />
          </div>

          <div className="backBtn">
            <SmallButton msg="back to recipes" icon="backward" to="/recipes" color="white" />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default RecipeDetail;
