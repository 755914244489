import styled from "styled-components";
import React from "react";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import { BsCart } from "react-icons/bs";
import { GreenShopButton, GreenIconStyle, MsgWrapper } from "./styles";

const ForwardIcon = styled(IoArrowForward)``;
const BackwardIcon = styled(IoArrowBack)``;
const CartIcon = styled(BsCart)``;

const GreenButton = (props) => {
    const col = props.color;
    return (
        <GreenShopButton to={props.to} color={col} target={props.target}>
            <GreenIconStyle color={col} yellow={props.yellow}>
                {props.icon === "cart" ? <CartIcon /> : props.icon === "backward" ? <BackwardIcon /> : <ForwardIcon />}
            </GreenIconStyle>
            <MsgWrapper>{props.msg}</MsgWrapper>
        </GreenShopButton>
    );
};
export default GreenButton;
