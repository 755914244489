import styled from "styled-components";
import { Link } from "gatsby";
export const ShopButton = styled(Link)`
  min-width: 18vw;
  max-width: fit-content;
  text-decoration: none;
  padding: 0 1vw 0 0;
  cursor: pointer;
  text-transform: uppercase;
  padding-right: 3rem;
  display: flex;
  text-align: center;
  justify-content: left;
  align-items: center;
  position: relative;
  font-size: 1.7vw;
  border-radius: 120px 0px 180px 120px;
  color: var(--white);
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 120px 3px 180px 120px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.4);
    transition: width 0.3s ease-in-out;
    background-color: var(--darkorange);
  }
  &:hover::before {
    width: 102%;
    transition: width 0.3s ease-in-out;
    background-color: var(--white);
  }
  &:hover {
    color: var(--lightorange);
  }
  @media screen and (max-width: 880px) {
    padding-right: 2rem;
  }
  @media screen and (max-width: 630px) {
    min-width: 40vw;
    font-size: 3.86vw;
  }
  @media screen and (max-width: 400px) {
    padding-right: 25px;
    width: 100%;
  }
`;
export const MsgWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  @media screen and (max-width: 880px) {
    font-size: 2.3vw;
  }
  @media screen and (max-width: 630px) {
    font-size: 22px;
  }

  @media screen and (max-width: 400px) {
    font-size: 15px;
    margin-left: 5px;
  }
`;
export const IconStyle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
  background-color: var(--lightorange);
  width: auto;
  font-size: 2.2vw;
  padding: 10px;
  border-radius: 100px;
  ${ShopButton}:hover & {
    background-color: var(--white);
    color: var(--darkorange);
  }
  @media screen and (max-width: 880px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 630px) {
    font-size: 6.5vw;
  }
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`;

export const GreenShopButton = styled(Link)`
  min-width: 18vw;
  max-width: fit-content;
  text-decoration: none;
  padding: 0 2vw 0 0;
  cursor: pointer;
  text-transform: uppercase;
  padding-right: 3rem;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  justify-content: left;
  align-items: center;
  position: relative;
  font-size: 1.7vw;
  border-radius: 120px 0px 180px 120px;
  color: var(--yellow);
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 120px 3px 180px 120px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.4);
    transition: width 0.3s ease-in-out;
    background-color: var(--darkgreen);
  }
  &:hover::before {
    width: 102%;
    transition: width 0.3s ease-in-out;
    background-color: var(--yellow);
    @media screen and (max-width: 630px) {
      background-color: var(--darkgreen);
    }
  }
  &:hover {
    color: var(--darkgreen);
    @media screen and (max-width: 630px) {
      color: var(--yellow);
    }
  }
  @media screen and (max-width: 880px) {
    padding-right: 2rem;
  }
  @media screen and (max-width: 400px) {
    padding-right: 25px;
    width: 100vw;
  }
  @media screen and (max-width: 630px) {
    min-width: 50vw;
    font-size: 5.55vw;
  }
`;

export const GreenIconStyle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
  background-color: var(--btngreen);
  width: auto;
  font-size: 2.2vw;
  padding: 10px;
  border-radius: 100px;
  ${GreenShopButton}:hover & {
    background-color: var(--lightyellow);
    color: var(--darkgreen);
    @media screen and (max-width: 630px) {
      background-color: var(--btngreen);
      color: var(--lightyellow);
    }
  }
  @media screen and (max-width: 880px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 630px) {
    font-size: 6.5vw;
  }
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`;

export const SmallShopButton = styled(Link)`
  min-width: 250px;
  max-width: fit-content;
  text-decoration: none;
  padding: 0 5px 0 0;
  cursor: pointer;
  text-transform: uppercase;
  padding-right: 2.5rem;
  display: flex;
  text-align: center;
  justify-content: left;
  align-items: center;
  position: relative;
  font-size: clamp(0.8rem, 1.2vw, 25px);
  border-radius: 120px 0px 180px 120px;
  color: var(--darkgreen);
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 120px 3px 180px 120px;
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.4);
    transition: width 0.3s ease-in-out;
    background-color: var(--white);
  }
  &:hover::before {
    width: 102%;
    transition: width 0.3s ease-in-out;
    background-color: var(--white);
  }
  &:hover {
    color: var(--lightgreen);
  }
  @media screen and (max-width: 932px) {
    min-width: 200px;
  }
  @media screen and (max-width: 630px) {
    min-width: 200px;
    font-size: 3.86vw;
  }
`;
export const SmallIconStyle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
  background-color: var(--white);
  width: auto;
  font-size: clamp(1.4rem, 1.3vw, 55px);
  padding: 10px;
  border-radius: 100px;
  ${ShopButton}:hover & {
    background-color: var(--white);
    color: var(--lightgreen);
  }
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`;
export const OYShopButton = styled(Link)`
  min-width: 18vw;
  max-width: fit-content;
  text-decoration: none;
  padding: 0 2vw 0 0;
  cursor: pointer;
  text-transform: uppercase;
  padding-right: 3rem;
  display: flex;
  text-align: center;
  justify-content: left;
  align-items: center;
  position: relative;
  font-size: 1.7vw;
  border-radius: 120px 0px 180px 120px;
  color: var(--white);
  &::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 120px 3px 180px 120px;
    box-shadow: 1px 3px 8px rgba(0, 0, 0, 0.4);
    transition: width 0.3s ease-in-out;
    background-color: var(--darkorange);
  }
  &:hover::before {
    width: 102%;
    transition: width 0.3s ease-in-out;
    background-color: var(--yellow);
  }
  &:hover {
    color: var(--darkgreen);
  }
  @media screen and (max-width: 880px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 630px) {
    min-width: 50vw;
    font-size: 5.55vw;
  }
  @media screen and (max-width: 400px) {
    padding-right: 25px;
    width: 100vw;
  }
`;
export const OYIconStyle = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 1;
  background-color: var(--lightorange);
  width: auto;
  font-size: 2.2vw;
  padding: 10px;
  border-radius: 100px;
  ${OYShopButton}:hover & {
    background-color: var(--lightyellow);
    color: var(--darkgreen);
  }
  @media screen and (max-width: 880px) {
    font-size: 2.5vw;
  }
  @media screen and (max-width: 630px) {
    font-size: 6.5vw;
  }
  @media screen and (max-width: 400px) {
    font-size: 18px;
  }
`;
