import styled from "styled-components";
import React from "react";
import { IoArrowForward, IoArrowBack } from "react-icons/io5";
import { BsCart } from "react-icons/bs";
import { ShopButton, IconStyle, MsgWrapper } from "./styles";

const ForwardIcon = styled(IoArrowForward)``;
const BackwardIcon = styled(IoArrowBack)``;
const CartIcon = styled(BsCart)``;

const OrangeButton = (props) => {
    return (
        <ShopButton to={props.to} color={props.color} target={props.target}>
            <IconStyle>{props.icon === "cart" ? <CartIcon /> : props.icon === "backward" ? <BackwardIcon /> : <ForwardIcon />}</IconStyle>
            <MsgWrapper>{props.msg}</MsgWrapper>
        </ShopButton>
    );
};
export default OrangeButton;
