import React from "react";
import Slider from "react-slick";
import "./RecipesSlider.scss";
import Card from "../Card/BasicCard";

const RecipesSlider = ({ recipes, currentRecipe }) => {
  // this component is not "mobile-first"
  // breakpoints are about max-width property
  const settings = {
    dots: false,
    lazyLoad: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  let currentRecipes = recipes.filter((card, i) => card.id !== currentRecipe);

  return (
    <Slider {...settings}>
      {currentRecipes.map((item, i) => (
        <Card
          key={item.title}
          title={item.title}
          classNames=""
          path={item.path}
          image={item.desktopImage}
          mobileImage={item.mobileImage}
          mealType={item.typeOfMeal}
          prepTime={item.preparationTime}
          isSimpleCard={true}
        />
      ))}
    </Slider>
  );
};

export default RecipesSlider;
