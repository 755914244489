import React from "react";
import { Link } from "gatsby";
import Icon from "@mdi/react";
import { mdiFoodVariant, mdiTimerOutline } from "@mdi/js";

import "./basicCard.scss";

const BasicCard = ({ title, path, image, mobileImage, classNames, mealType, prepTime, isSimpleCard }) => {
  return (
    <div className={`basic-card ${classNames}`}>
      <Link title={title} to={`/recipes/${path}`}>
        <img className="mobile-image" src={mobileImage} alt={title} loading="lazy" />
        <img className="desktop-image" src={image} alt={title} loading="lazy" />
        <div className={`basic-card__info w-100 d-flex flex-column justify-content-center ${isSimpleCard && "basic-card__simple"}`}>
          <div className={`title`}>{title}</div>
          {!isSimpleCard && (
            <div className="valuesSection">
              <div className="values">
                <Icon path={mdiTimerOutline} title="timer" size={0.7} horizontal color="#0f4735" /> {prepTime} mins
              </div>
              <div className="values">|</div>
              <div className="values">
                <Icon path={mdiFoodVariant} title="food" size={0.7} horizontal color="#0f4735" /> {mealType}
              </div>
            </div>
          )}
        </div>
      </Link>
    </div>
  );
};

export default BasicCard;
