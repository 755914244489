import japaneseCurry1 from "../img/recipes/2_JapaneseCurry.webp";
import japaneseCurry2 from "../img/recipes/2_JapaneseCurry2.webp";
import japaneseCurry3 from "../img/recipes/2_JapaneseCurry3.webp";
import spaghettiBolognese1 from "../img/recipes/1_SpaghettiBolognese.webp";
import spaghettiBolognese2 from "../img/recipes/1_SpaghettiBolognese2.webp";
import spaghettiBolognese3 from "../img/recipes/1_SpaghettiBolognese3.webp";
import crispyNuggetKatsu1 from "../img/recipes/3_CrispyNuggetKatsu.webp";
import crispyNuggetKatsu2 from "../img/recipes/3_CrispyNuggetKatsu2.webp";
import crispyNuggetKatsu3 from "../img/recipes/3_CrispyNuggetKatsu3.webp";
import cesarSaladSpicyNuggets1 from "../img/recipes/4_CesarSaladSpicyNuggets.webp";
import cesarSaladSpicyNuggets2 from "../img/recipes/4_CesarSaladSpicyNuggets2.webp";
import cesarSaladSpicyNuggets3 from "../img/recipes/4_CesarSaladSpicyNuggets3.webp";
import crispyStripNachos1 from "../img/recipes/5_CrispyStripNachos.webp";
import crispyStripNachos2 from "../img/recipes/5_CrispyStripNachos2.webp";
import crispyStripNachos3 from "../img/recipes/5_CrispyStripNachos3.webp";
import spicyKoreanBowl1 from "../img/recipes/6_SpicyKoreanBowl.webp";
import spicyKoreanBowl2 from "../img/recipes/6_SpicyKoreanBowl2.webp";
import spicyKoreanBowl3 from "../img/recipes/6_SpicyKoreanBowl3.webp";
import mapoTofu1 from "../img/recipes/7_MapoTofu.webp";
import mapoTofu2 from "../img/recipes/7_MapoTofu2.webp";
import mapoTofu3 from "../img/recipes/7_MapoTofu3.jpg";
import gingerTeriyakiBowl1 from "../img/recipes/8_GingerTeriyakiBowl.webp";
import gingerTeriyakiBowl2 from "../img/recipes/8_GingerTeriyakiBowl2.webp";
import gingerTeriyakiBowl3 from "../img/recipes/8_GingerTeriyakiBowl3.webp";
import soboroDonburi1 from "../img/recipes/9_SoboroDonburi.webp";
import soboroDonburi2 from "../img/recipes/9_SoboroDonburi2.webp";
import soboroDonburi3 from "../img/recipes/9_SoboroDonburi3.webp";
import crispyCutletRiceBurger1 from "../img/recipes/10_CrispyCutletRiceBurger.webp";
import crispyCutletRiceBurger2 from "../img/recipes/10_CrispyCutletRiceBurger2.webp";
import crispyCutletRiceBurger3 from "../img/recipes/10_CrispyCutletRiceBurger3.webp";
/** 
 * Products img
*/
import popcornBites from "../img/products/snackseries/pb1.webp";
import crispyNuggets from "../img/products/snackseries/cn1.webp";
import spicyNuggets from "../img/products/snackseries/sn1.webp";
import crispyStrips from "../img/products/snackseries/cs1.webp";
import cookedMince from "../img/products/jseries/jscm1.webp";
import crispyKatsu from "../img/products/jseries/jsck1.webp";

export const recipeDetailData = [
    {
        id:0,
        title:"<small>Plant-based</small><br> Spaghetti Bolognese",
        metaTitle:"Plant-based Spaghetti Bolognese",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"2-4",
        recipeImage1:spaghettiBolognese1,
        recipeImage1Alt:"",
        recipeImage2:spaghettiBolognese2,
        recipeImage2Alt:"",
        recipeImage3:spaghettiBolognese3,
        recipeImage3Alt:"",
        description:"Try out this plant-based Spaghetti Bolognese recipe mixed perfectly with tomatoes in this truly classic Italian dish. Just as meaty and rich as its original, this hearty variation is packed with healthy nutrients that will leave you full and satisfied.",
        product: {
            title:'Cooked Mince',
            path:'/products/cooked-mince',
            img:cookedMince
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Cooked Mince",
                preparation:"Thawed",
                unit:"gm",
                quantity:"360",
            },
            {
                ingredient:"Spaghetti or Favorite Dried Pasta",
                preparation:"",
                unit:"gm",
                quantity:"250",
            },
            {
                ingredient:"Favorite Prepared Pasta Sauce",
                preparation:"",
                unit:"gm",
                quantity:"400",
            },
            {
                ingredient:"Carrot",
                preparation:"Shredded",
                unit:"cup",
                quantity:"1/4",
            },
            {
                ingredient:"Onion",
                preparation:"Finely Diced",
                unit:"gm",
                quantity:"1/4",
            },
            {
                ingredient:"Garlic",
                preparation:"Minced",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"Olive Oil",
                preparation:"",
                unit:"tbl",
                quantity:"3",
            },
            {
                ingredient:"Salt",
                preparation:"",
                unit:"tsp",
                quantity:"1/2",
            },
            {
                ingredient:"Black Pepper",
                preparation:"",
                unit:"tsp",
                quantity:"1/4",
            },
            {
                ingredient:"Italian Basil",
                preparation:"Whole Leaves",
                unit:"As desired",
                quantity:"As desired",
            }
        ],
        cookTime:"20",
        procedures:[
            "Bring 4qts of water to boil, add 1tbl spoon of salt and boil pasta per package instructions",
            "Heat olive oil in a large sauté pan over medium high heat",
            "Add in <strong>FIRST PRIDE Cooked Mince</strong>, carrots, and onions.",
            "Sauté for 4 minutes or until onion is translucent and tender, add in garlic and salt. ",
            "Sauté for 2 minutes and add in tomato sauce, black pepper and basil.",
            "Stir to combine and reduce heat to simmer and let cook for 6-7 minutes stirring occasionally",
            "Drain the pasta and serve topped with the sauce",
        ]
    },
    {
        id:1,
        title:"<small>Plant-based</small><br> Spicy Korean Rice Bowl",
        metaTitle:"Plant-based Spicy Korean Rice Bowl",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"2-4",
        recipeImage1:spicyKoreanBowl1,
        recipeImage1Alt:"",
        recipeImage2:spicyKoreanBowl2,
        recipeImage2Alt:"",
        recipeImage3:spicyKoreanBowl3,
        recipeImage3Alt:"",
        description:"Put a plant-based spin on this traditional, go-to Korean dish! Using the standard Korean spice mixture, Gochujang, this flavorful dish can be made in no time --- perfect for any night of the week. Enjoy the assorted vegetables and combincation of  spicy, savory sweetness that will leave you wanting for more.",
        product: {
            title:'Cooked Mince',
            path:'/products/cooked-mince',
            img:cookedMince
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Cooked Mince",
                preparation:"Thawed",
                unit:"gm",
                quantity:"360",
            },
            {
                ingredient:"Spring Onion / Japanese Leek",
                preparation:"Thinly Sliced",
                unit:"cup",
                quantity:"1/4",
            },
            {
                ingredient:"Garlic",
                preparation:"Minced",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"Prepared Gochujang Paste",
                preparation:"",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"Mirin",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Sake",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Sesame Oil",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Sugar",
                preparation:"",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"White Sesame Seed",
                preparation:"",
                unit:"tsp",
                quantity:"1",
            },
            {
                ingredient:"Fresh Chili",
                preparation:"Sliced",
                unit:"As desired",
                quantity:"As desired",
            },
            {
                ingredient:"Vegetable Oil",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Rice",
                preparation:"",
                unit:"As desired",
                quantity:"As desired",
            }
        ],
        cookTime:"15",
        procedures:[
            "Heat oil over medium high heat",
            "Sauté <strong>FIRST PRIDE Cooked Mince</strong> for 4-5 minutes",
            "Add in spring onion/Japanese leek and garlic. Sauté for 2-3 minutes",
            "Add in gochujang, mirin, sesame oil, soy, sake and sugar. Sauté for 3 minutes",
            "Add in white sesame and chili, sauté for 1 minutes and remove from heat",
            "Serve over rice or as desired and enjoy!",
        ]
    },
    {
        id:2,
        title:"<small>Plant-based</small><br> Mapo Tofu",
        metaTitle:"Plant-based Mapo Tofu",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"2-4",
        recipeImage1:mapoTofu1,
        recipeImage1Alt:"",
        recipeImage2:mapoTofu2,
        recipeImage2Alt:"",
        recipeImage3:mapoTofu3,
        recipeImage3Alt:"",
        description:" Indulge in the authentic Sichuan flavours of the popular Mapo Tofu. A great side dish to be eaten with rice or noodles, you'll get to experience a slightly spicy taste with its unique numbing effect that will linger on your tongue. Flavourful and healthy, with protein made from plants.",
        product: {
            title:'Cooked Mince',
            path:'/products/cooked-mince',
            img:cookedMince
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Cooked Mince",
                preparation:"Thawed",
                unit:"gm",
                quantity:"180",
            },
            {
                ingredient:"Firm Tofu",
                preparation:"Cubed",
                unit:"gm",
                quantity:"150",
            },
            {
                ingredient:"Mapo Tofu Seasoning Pack",
                preparation:"",
                unit:"pack",
                quantity:"1",
            },
            {
                ingredient:"Garlic",
                preparation:"Minced",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"Spring Onion",
                preparation:"Thinly Sliced",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Fresh Chili",
                preparation:"Thinly Sliced",
                unit:"As desired",
                quantity:"As desired",
            },
            {
                ingredient:"Vegetable Oil",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Rice",
                preparation:"",
                unit:"As desired",
                quantity:"As desired",
            }
        ],
        cookTime:"10-15",
        procedures:[
            "Heat oil over medium high heat",
            "Sauté <strong>FIRST PRIDE Cooked Mince</strong> for 4-5 minutes",
            "Add in garlic, half of spring onion and chili. Stir fry for 1-2 minutes",
            "Add in Mapo Tofu seasoning pack and stir to combine",
            "Serve with rice and top with remaining spring onions. Enjoy!",
        ]
    },
    {
        id:3,
        title:"<small>Plant-based</small><br> Ginger Teriyaki Udon Bowl",
        metaTitle:"Plant-based Ginger Teriyaki Udon Bowl",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"2-4",
        recipeImage1:gingerTeriyakiBowl1,
        recipeImage1Alt:"",
        recipeImage2:gingerTeriyakiBowl2,
        recipeImage2Alt:"",
        recipeImage3:gingerTeriyakiBowl3,
        recipeImage3Alt:"",
        description:"Have this simple yet impressive meal of chewy udon noodles and tender FIRST PRIDE Cooked Mince with umami teriyaki sauce. High in both protein and fiber, this dish is worth a try --- whether you are a vegan or simply a fan of Japanese cuisine.",
        product: {
            title:'Cooked Mince',
            path:'/products/cooked-mince',
            img:cookedMince
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Cooked Mince",
                preparation:"Thawed",
                unit:"gm",
                quantity:"180",
            },
            {
                ingredient:"Young Ginger",
                preparation:"Grated with juice",
                unit:"tsp",
                quantity:"1",
            },
            {
                ingredient:"Broccoli",
                preparation:"Cut into small pcs",
                unit:"cup",
                quantity:"1/2",
            },
            {
                ingredient:"Sugar Snap Peas / Edamame",
                preparation:"Cleaned",
                unit:"",
                quantity:"",
            },
            {
                ingredient:"Teriyaki Sauce",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Udon Noodles",
                preparation:"Boiled as per instructions on pack",
                unit:"As desired",
                quantity:"As desired",
            },
            {
                ingredient:"Vegetable Oil",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Pickled Ginger",
                preparation:"",
                unit:"As desired",
                quantity:"As desired",
            },
        ],
        cookTime:"15",
        procedures:[
            "Heat oil over medium high heat",
            "Sauté <strong>FIRST PRIDE Cooked Mince</strong> for 4-5 minutes",
            "Add in ginger, broccoli, and peas/edamame. Stir fry for 3-4 minutes",
            "Add in teriyaki sauce. Stir fry for 2 minutes or until sauce has thickened to coat a spoon.",
            "Add in udon noodles and stir fry for 1-2 minutes to combine. Remove from heat.",
            "Serve in a deep bowl, garnish with pickled ginger and enjoy!",
        ]
    },
    {
        id:4,
        title:"<small>Plant-based</small><br> Soboro Donburi",
        metaTitle:"Plant-based Soboro Donburi",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"2-4",
        recipeImage1:soboroDonburi1,
        recipeImage1Alt:"",
        recipeImage2:soboroDonburi2,
        recipeImage2Alt:"",
        recipeImage3:soboroDonburi3,
        recipeImage3Alt:"",
        description:"Soboro Don is a Japanese comfort meal for both kids and adults, and is easy to put together! Only taking 10 minutes, you can get a sweet & savory sauce mixed with FIRST PRIDE Cooked Mince topped on steamed rice effortlessly. Simple, fast, and well-thought-out, you can never go wrong with this classic Japanese dish.",
        product: {
            title:'Cooked Mince',
            path:'/products/cooked-mince',
            img:cookedMince
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Cooked Mince",
                preparation:"Thawed",
                unit:"gm",
                quantity:"360",
            },
            {
                ingredient:"Spring Onion / Japanese Leek",
                preparation:"Thinly Sliced",
                unit:"cup",
                quantity:"1/4",
            },
            {
                ingredient:"Ginger",
                preparation:"Grated ",
                unit:"tbl",
                quantity:"1/2",
            },
            {
                ingredient:"Mirin",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Soy Sauce",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Sake (optional)",
                preparation:"can use half as much rice vinegar",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Peas (Fresh or Frozen)",
                preparation:"Thawed",
                unit:"cup",
                quantity:"1/2",
            },
            {
                ingredient:"Red Pickled Ginger",
                preparation:"",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"Sugar",
                preparation:"",
                unit:"tbl",
                quantity:"1",
            },
            {
                ingredient:"Vegetable Oil",
                preparation:"",
                unit:"tbl",
                quantity:"2",
            },
            {
                ingredient:"Rice",
                preparation:"",
                unit:"As desired",
                quantity:"As desired",
            }
        ],
        cookTime:"10",
        procedures:[
            "Heat oil over medium high heat",
            "Sauté <strong>FIRST PRIDE Cooked Mince</strong> for 4-5 minutes",
            "Add in spring onion/Japanese leek and grated ginger. Sauté for 2 minutes",
            "Add in mirin, soy, sake and sugar, stir to dissolve sugar and cook until liquid is nearly gone",
            "Add in peas and stir to combine.Take off heat.",
            "Serve over rice and add pickled ginger as garnish."
        ]
    },
    {
        id:5,
        title:"<small>Plant-based</small><br> Popcorn Bites Japanese Curry",
        metaTitle:"Plant-based Popcorn Bites Japanese Curry",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"2",
        recipeImage1:japaneseCurry1,
        recipeImage1Alt:"",
        recipeImage2:japaneseCurry2,
        recipeImage2Alt:"",
        recipeImage3:japaneseCurry3,
        recipeImage3Alt:"",
        description:"Delicious Japanese Popcorn Bites curry recipe for a weeknight dinner! Crispy on the outside and tender at the inside of pieces of FIRST PRIDE popcorn bites, carrots, and potatoes cooked in a rich savory curry sauce, this Japanese version of curry is a must-have for your family meal. The perfect combination of ingredients makes every bite so comforting and exciting to eat. ",
        product: {
            title:'Popcorn Bites',
            path:'/products/popcorn-bites',
            img:popcornBites
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Popcorn Bites",
                preparation:"as per instructions on pack",
                unit:"gm",
                quantity:"170",
            },
            {
                ingredient:"Steamed Rice",
                preparation:"As desired",
                unit:"gm",
                quantity:"200 (uncooked)",
            },
            {
                ingredient:"Japanese Curry Roux Cube",
                preparation:"as per instructions on pack",
                unit:"pcs",
                quantity:"2",
            },
            {
                ingredient:"Carrot",
                preparation:"Large Dice",
                unit:"gm",
                quantity:"200",
            },
            {
                ingredient:"Potato",
                preparation:"Large Dice",
                unit:"gm",
                quantity:"200",
            },
            {
                ingredient:"White Onion",
                preparation:"Large Dice",
                unit:"gm",
                quantity:"100",
            },
            {
                ingredient:"Water",
                preparation:"",
                unit:"ml",
                quantity:"350",
            },
            {
                ingredient:"Pickled Ginger",
                preparation:"",
                unit:"gm",
                quantity:"50",
            },
            {
                ingredient:"Parsley",
                preparation:"Finely Chopped",
                unit:"gm",
                quantity:"20",
            },
        ],
        cookTime:"30-35",
        procedures:[
            "Bring water to a boil",
            "Add in carrots and potatoes, cook for 12-15min or until fork tender",
            "Add in onion and Japanese Curry cube",
            "Reduce heat to medium and stir occasionally for 8-10 minutes or until sauce has thickened. Reduce heat to low and cover to keep warm ",
            "Cook <strong>FIRST PRIDE Popcorn Bites</strong> as per instructions on pack. ",
            "Plate rice, add the curry,  and garnish with pickled ginger and parsley as desired.",
            "Top Popcorn Bites on the curry. ",
        ]
    },
    {
        id:6,
        title:"<small>Plant-based</small><br> Crispy Nugget Katsu Sando",
        metaTitle:"Plant-based Crispy Nugget Katsu Sando",
        metaDescription:"",
        brand:"First Pride",
        type:"Snack",
        servings:"2-6",
        recipeImage1:crispyNuggetKatsu1,
        recipeImage1Alt:"",
        recipeImage2:crispyNuggetKatsu2,
        recipeImage2Alt:"",
        recipeImage3:crispyNuggetKatsu3,
        recipeImage3Alt:"",
        description:"Enjoy this indulging yet healthy, plant-based snack in 10 minutes! This Japanese-style sandwich showcases the crispy coating of FIRST PRIDE Crispy Nuggets that perfectly contrasts with the softness of the bread. Take a bite and you'll find a juicy plant-based nugget that will leave you satisfied... and wanting for more!",
        product: {
            title:'Crispy Nuggets',
            path:'/products/crispy-nuggets',
            img:crispyNuggets
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Crispy Nuggets",
                preparation:"as per instructions on pack",
                unit:"gm",
                quantity:"170",
            },
            {
                ingredient:"Thick Slice White Sandwich Bread",
                preparation:"",
                unit:"pcs",
                quantity:"4",
            },
            {
                ingredient:"White Cabbage",
                preparation:"Thinly Shredded",
                unit:"gm",
                quantity:"200",
            },
            {
                ingredient:"Katsu Sauce",
                preparation:"",
                unit:"ml",
                quantity:"50",
            },
            {
                ingredient:"Japanese Mayonnaise",
                preparation:"",
                unit:"gm",
                quantity:"100",
            },
            {
                ingredient:"Karashi Mustard / Chinese Hot Mustard (Optional)",
                preparation:"",
                unit:"gm",
                quantity:"100",
            },
            {
                ingredient:"Bamboo or Steel Skewers",
                preparation:"",
                unit:"pcs",
                quantity:"8",
            }
        ],
        cookTime:"10",
        procedures:[
            "Prepare <strong>FIRST PRIDE Crispy Nuggets</strong> as per instructions on pack.",
            "Lightly toast sandwich bread ",
            "Spread Katsu Sauce and Japanese Mayo on the bread.",
            "Spread the cabbage generously on top of the Japanese mayo. Then, place a piece of Crispy Nuggets on top.",
            "Add mayonnaise and mustard, before closing with a slice of bread. This will should make a complete mini-sandwich. ",
            "Repeat until you get 3 more mini-sandwiches.",
            "Stack the three sandwiches with mayo and mustard and the fourth on top. Then, skewer through the center of the stacked sandwiches and press down firmly.",
        ]
    },
    {
        id:7,
        title:"<small>Plant-based</small><br> Caesar Salad with Spicy Nuggets",
        metaTitle:"Caesar Salad with Plant-based Spicy Nuggets",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"4",
        recipeImage1:cesarSaladSpicyNuggets1,
        recipeImage1Alt:"",
        recipeImage2:cesarSaladSpicyNuggets2,
        recipeImage2Alt:"",
        recipeImage3:cesarSaladSpicyNuggets3,
        recipeImage3Alt:"",
        description:"Add a spicy, flavorful twist and some extra crunch to the classic Caesar salad recipe with our Spicy Nuggets! Meaty yet plant-based, it is loaded with romaine and nutrients. With its creamy & refreshing dressing, it is an ideal for lunch with friends and family.",
        product: {
            title:'Spicy Nuggets',
            path:'/products/spicy-nuggets',
            img:spicyNuggets
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Spicy Nuggets",
                preparation:"as per instructions on pack",
                unit:"pcs",
                quantity:"10",
            },
            {
                ingredient:"Romain / Cos Lettuce",
                preparation:"Washed and Cut into desired size",
                unit:"gm",
                quantity:"200",
            },
            {
                ingredient:"Cherry Tomato",
                preparation:"Washed and Cut into desired size",
                unit:"gm",
                quantity:"100",
            },
            {
                sectionName:"Caesar dressing",
            },
            {
                ingredient:"Vegan Mayonnaise",
                preparation:"",
                unit:"gm",
                quantity:"200",
                sectionIngredient: true
            },
            {
                ingredient:"Dijon / Wholegrain Mustard",
                preparation:"",
                unit:"gm",
                quantity:"25",
                sectionIngredient: true
            },
            {
                ingredient:"Garlic",
                preparation:"Finely minced",
                unit:"gm",
                quantity:"15",
                sectionIngredient: true
            },
            {
                ingredient:"Capers",
                preparation:"Finely minced",
                unit:"gm",
                quantity:"10",
                sectionIngredient: true
            },
            {
                ingredient:"Lemon",
                preparation:"Zest & Juiced",
                unit:"pc",
                quantity:"1",
                sectionIngredient: true
            },
            {
                ingredient:"Brown Sugar",
                preparation:"",
                unit:"gm",
                quantity:"50",
                sectionIngredient: true
            },
            {
                ingredient:"Extra Virgin Olive Oil",
                preparation:"",
                unit:"ml",
                quantity:"50",
                sectionIngredient: true
            },
            {
                sectionName:"Crouton",
            },
            {
                ingredient:"Crusty bread pieces",
                preparation:"Cubed or Torn to 2cm pieces",
                unit:"gm",
                quantity:"100",
                sectionIngredient: true
            },
            {
                ingredient:"Olive oil",
                preparation:"",
                unit:"ml",
                quantity:"50",
                sectionIngredient: true
            },
            {
                ingredient:"Italian seasoning",
                preparation:"",
                unit:"gm",
                quantity:"10",
                sectionIngredient: true
            },
            {
                ingredient:"Chili Flake",
                preparation:"",
                unit:"gm",
                quantity:"5",
                sectionIngredient: true
            },
            {
                ingredient:"Garlic powder",
                preparation:"",
                unit:"gm",
                quantity:"5",
                sectionIngredient: true
            },
            {
                ingredient:"Salt",
                preparation:"",
                unit:"gm",
                quantity:"5",
                sectionIngredient: true
            }
        ],
        cookTime:"20",
        procedures:[
            "Cook <strong>FIRST PRIDE Spicy Nuggets</strong> as per instructions on pack. Set aside.",
            "Toss bread pieces with crouton ingredients until thoroughly coated",
            "Place bread pieces on a baking tray and toast in an oven at 185°C for 12 minutes.",
            "Stir the croutons once or twice during the toasting to ensure even browning.",
            "In a large bowl whisk together all dressing ingredients until fully combined. Set aside",
            "Add in lettuce / romaine, tomato and croutons and toss to combine",
            "Serve onto salad plates/bowls and top with Spicy Nuggets.",
            "Serve and Enjoy!",
        ]
    },
    {
        id:8,
        title:"<small>Plant-based</small><br> Crispy Strips Nachos",
        metaTitle:"Plant-based Crispy Strips Nachos",
        metaDescription:"",
        brand:"FirstPride",
        type:"Snack",
        servings:"2-4",
        recipeImage1:crispyStripNachos1,
        recipeImage1Alt:"",
        recipeImage2:crispyStripNachos2,
        recipeImage2Alt:"",
        recipeImage3:crispyStripNachos3,
        recipeImage3Alt:"",
        description:"Enjoy this iconic, Tex-Mex game day food with this plant-based variation….more meaty and more filling with plant-based Strips! This mouth-watering recipe is quick and easy with a flavorful punch--- you'll be back cheering for the game in no time!",
        product: {
            title:'Crispy Strips',
            path:'/products/crispy-strips',
            img:crispyStrips
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Crispy Strips",
                preparation:"as per instructions on pack",
                unit:"pcs",
                quantity:"8",
            },
            {
                ingredient:"Avocado",
                preparation:"Diced",
                unit:"pcs",
                quantity:"1",
            },
            {
                ingredient:"Jalapeno",
                preparation:"Sliced thin",
                unit:"pcs",
                quantity:"2",
            },
            {
                ingredient:"Tomato",
                preparation:"Seeded & Diced ",
                unit:"gm",
                quantity:"220",
            },
            {
                ingredient:"Onion",
                preparation:"Diced",
                unit:"gm",
                quantity:"75",
            },
            {
                ingredient:"Garlic",
                preparation:"Minced",
                unit:"gm",
                quantity:"30",
            },
            {
                ingredient:"Coriander",
                preparation:"Chopped",
                unit:"gm",
                quantity:"20",
            },
            {
                ingredient:"Lime",
                preparation:"Zested & Juiced",
                unit:"pcs",
                quantity:"2",
            },
            {
                ingredient:"Thai Chili",
                preparation:"Chopped",
                unit:"pcs",
                quantity:"2",
            },
            {
                ingredient:"Sriracha Sauce",
                preparation:"",
                unit:"ml",
                quantity:"100",
            },
            {
                ingredient:"Cheddar or Vegan Cheese (optional)",
                preparation:"Shredded",
                unit:"gm",
                quantity:"100",
            },
            {
                ingredient:"Salt",
                preparation:"",
                unit:"gm",
                quantity:"5",
            },
            {
                ingredient:"Black Pepper",
                preparation:"",
                unit:"gm",
                quantity:"2",
            },
        ],
        cookTime:"20",
        procedures:[
            "Cook <strong>FIRST PRIDE Crispy Strips</strong> as per instructions on pack.",
            "Combine tomato, onion, garlic, coriander, lime, chili, salt and pepper into a bowl and mix to combine. Taste and adjust lime, salt and pepper as desired. Keep chilled",
            "Place Crispy Strips into an oven safe baking dish",
            "On top of the Crispy Strips, add sriracha, jalapeno and cheese. Bake in a 200'c pre-heated oven for 6-8 minutes",
            "Remove from oven and top with avocado and tomato salsa",
            "Serve on a heat safe surface and enjoy",
        ]
    },
    {
        id:9,
        title:"<small>Plant-based</small><br> Crispy Cutlet Rice Burger",
        metaTitle:"Plant-based Crispy Cutlet Rice Burger",
        metaDescription:"",
        brand:"First Pride",
        type:"Meal",
        servings:"1",
        recipeImage1:crispyCutletRiceBurger1,
        recipeImage1Alt:"",
        recipeImage2:crispyCutletRiceBurger2,
        recipeImage2Alt:"",
        recipeImage3:crispyCutletRiceBurger3,
        recipeImage3Alt:"",
        description:`Our delicious plant-based "chicken" cutlets, or katsu, rice burger was inspired by tha tradtional Japanese onigirazu. Crispy on the inside with the katsu layer and a hint of chilli sauce, you can easily enjoy this fusion meal on-the-go`,
        product: {
            title:'Crispy Katsu (Cutlets)',
            path:'/products/crispy-katsu',
            img:crispyKatsu
        },
        ingredients:[
            {
                ingredient:"FIRST PRIDE Crispy Katsu (Cutlets)",
                preparation:"as per instructions on pack",
                unit:"piece",
                quantity:"1",
            },
            {
                ingredient:"Cucumber",
                preparation:"Sliced",
                unit:"",
                quantity:"As desired",
            },
            {
                ingredient:"Tomato",
                preparation:"Sliced",
                unit:"",
                quantity:"As desired",
            },
            {
                ingredient:"Batavia leaf",
                preparation:"",
                unit:"",
                quantity:"As desired",
            },
            {
                ingredient:"Chicken rice chilli sauce",
                preparation:"",
                unit:"",
                quantity:"As desired",
            },
            {
                ingredient:"Fried garlic",
                preparation:"",
                unit:"",
                quantity:"Garnish",
            },
            {
                ingredient:"Fried shallot",
                preparation:"",
                unit:"",
                quantity:"Garnish",
            },
            {
                sectionName:"Rice Patties",
            },
            {
                ingredient:"Cooked short grain / sushi rice",
                preparation:"Cooked and cooled",
                unit:"gm",
                quantity:"250",
                sectionIngredient: true
            },
            {
                ingredient:"Scallion",
                preparation:"Chopped",
                unit:"",
                quantity:"Garnish",
                sectionIngredient: true
            },
            {
                ingredient:"Cilantro",
                preparation:"Chopped",
                unit:"",
                quantity:"Garnish",
                sectionIngredient: true
            },
            {
                ingredient:"Sesame oil",
                preparation:"",
                unit:"",
                quantity:"As desired",
                sectionIngredient: true
            },
            {
                ingredient:"Soy sauce",
                preparation:"",
                unit:"",
                quantity:"As desired",
                sectionIngredient: true
            }
        ],
        cookTime:"20-30",
        procedures:[
            "Fry <strong>FIRST PRIDE Crispy Katsu</strong> as per instructions on pack.",
            "Combine the rice, scallion and cilantro. Mix well.",
            "Divide the rice mixture into two and form two rice patties.",
            "Lightly toast the rice patties on a pan until golden brown on the surface.",
            "Lightly brush the surface of the rice patties with soy sauce and sesame oil.",
            "Build the burger using the rice patties, Crispy Katsu, cucumber, tomato, batavia leaf, and chilli sauce.",
            "Sprinkle the fried garlic and fried shallot on top of the burger to finish.",
        ]
    }
]