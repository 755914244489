import React from "react";

const infoPanels = (props) => {
    const {classes,type, cookTime, servings} = props;
    return(
        <div className={`infoPanels ${classes}`}>
            <div className="infoPanel"> 
                <div className="infoPanelTag">Type</div>
                <div className="infoPanelValue" >{type}</div>
            </div>
            <div className="infoPanel"> 
                <div className="infoPanelTag" >Cook Time</div>
                <div className="infoPanelValue" >{cookTime} mins</div>
            </div>
            <div className="infoPanel"> 
                <div className="infoPanelTag" >Servings</div>
                <div className="infoPanelValue" >{servings}</div>
            </div>
        </div>
    )
};

export default infoPanels;